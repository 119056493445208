import Vue from 'vue'
import App from './App.vue'
import firebase from "firebase/compat/app"

import 'firebase/compat/auth';
import store from "./store";
import router from "./routes/index"


Vue.config.productionTip = false


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAujeMw_i-duoq2NvcAPjm5yWP7j9bZi10",
  authDomain: "react-test-auth-7b3e9.firebaseapp.com",
  projectId: "react-test-auth-7b3e9",
  storageBucket: "react-test-auth-7b3e9.appspot.com",
  messagingSenderId: "291317551492",
  appId: "1:291317551492:web:245f06b60ddbd2c5bd4dd1",
  measurementId: "G-JYZEN4QMYZ"
};

firebase.initializeApp(firebaseConfig);



firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
