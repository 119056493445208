import { render, staticRenderFns } from "./CreatePDCST.vue?vue&type=template&id=dc167f54&scoped=true&v-if=user.loggedIn&"
import script from "./CreatePDCST.vue?vue&type=script&lang=js&"
export * from "./CreatePDCST.vue?vue&type=script&lang=js&"
import style0 from "./CreatePDCST.vue?vue&type=style&index=0&id=dc167f54&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc167f54",
  null
  
)

export default component.exports