<template>
  <b-navbar type="dark" variant="dark">
    <b-navbar-nav>
      <b-nav-item href="/">PDCST.ai</b-nav-item>
      <!-- <b-nav-item to="/login">Login</b-nav-item> -->
      <b-nav-item v-if="user.loggedIn" @click.prevent="signOut"
        >Sign Out</b-nav-item
      >
      <b-nav-item v-else to="login">Log In</b-nav-item>
      <b-nav-item v-if="user.loggedIn" to="/create">Create Podcast</b-nav-item>

      <b-nav-item-dropdown v-if="user.loggedIn" text="User" right>
        <b-dropdown-item href="#">Account</b-dropdown-item>
        <b-dropdown-item href="#">Settings</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase/compat/app";
export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "Home",
          });
        });
    },
  },
};
</script>