<template>
  <div :style="cssProps">
    <navbar></navbar>
    <main class="py-4">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import navbar from "./components/Navbar";
export default {
  components: {
    navbar,
  },
  data() {
    return {
      cssProps: {
        // backgroundImage: `url(${require("@/assets/audio.jpg")})`,
        backgroundColor: "#f0f0f5",
      },
    };
  },
};
</script>
<style>
html {
  position: relative;
  min-height: 100%;
  background-color: #262626;
}
body {
  margin-bottom: 109px;
}
footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>