<template v-if="user.loggedIn" >
  <div>
    <div id="dashboard" class="container">
      <h4>Welkom, {{ user.data.displayName }}</h4>
      <b-form-textarea
        id="textarea-rows"
        v-model="text"
        rows="8"
      ></b-form-textarea>

      <br />
      <div id="#wave" ref="wave"></div>
      <button
        class="button"
        href="#"
        @click.prevent="
          createPodcast({
            url: 'https://speak-api-ordhdrsbwa-ey.a.run.app/synth'
          })
        "
      >
        Creeer spraak
      </button>
      <button class="button" @click.prevent="toggleAudio()">play/ pause</button>
      <button class="button" @click.prevent="downloadFile()">download</button>
    </div>

    <br />
    <!-- <div class="container">
      <h2>Select ambient sound</h2>
      <table class="ambiences">
        <thead>
          <tr>
            <th scope="col">sound</th>
            <th scope="col">player</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="col">rain</th>
            <th scope="col">
              <div>
                <audio controls id="background">
                  <source :src="backgroundRain" type="audio/mpeg" />
                </audio>
              </div>
            </th>
          </tr>
          <tr>
            <th scope="col">restaurant</th>
            <th scope="col">
              <div>
                <audio controls>
                  <source :src="backgroundRestaurant" type="audio/mpeg" />
                </audio>
              </div>
            </th>
          </tr>
          <tr>
            <th scope="col">sea waves</th>
            <th scope="col">
              <div>
                <audio controls>
                  <source :src="backgroundWaves" type="audio/mpeg" />
                </audio>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </div> -->
  </div>
</template>
<script src="https://unpkg.com/vue"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/wavesurfer.js/1.3.7/wavesurfer.min.js"></script>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import WaveSurfer from "wavesurfer.js";
// const textToSpeech = require('@google-cloud/text-to-speech');
// Import other required libraries
const fs = require('fs');
const util = require('util');
// Creates a client
// const client = new textToSpeech.TextToSpeechClient();

export default {
  data() {
    return {
      text: "Voor spoed belt U altijd 112.",
      audiosource: null,
      messageBox: null,
      // backgroundRain: "https://www.pacdv.com/sounds/ambience_sounds/rain-6.mp3",
      // backgroundRestaurant:
        // "https://www.pacdv.com/sounds/ambience_sounds/restaurant-2.mp3",
      // backgroundWaves: "https://www.pacdv.com/sounds/ambience_sounds/s-w-2.mp3",
      // file: "https://www.pacdv.com/sounds/ambience_sounds/s-w-2.mp3",
      downloadLink: null,
    };
  },
  mounted() {
    console.log(this.$refs.wave);
    this.$nextTick(() => {
      this.wavesurfer = WaveSurfer.create({
        container: this.$refs.wave,
      });
    });
  },

  methods: {
    async createPodcast({ url }) {
      console.log("url = ", url)
      this.messageBox = null;
      console.log(this.text);
      console.log("User data = ", this.user)
      const payload = { text: this.text.replace(/\n/g, " "), user: this.user };
      console.log(payload);
      const response = await axios.post(url, payload, { responseType: "blob" } );
      this.messageBox = "Ready!";
      const blob = new Blob([response.data], { type: "audio/wave" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      this.downloadLink = blob
      console.log("download link = ", this.downloadLink)
      // link.download = "sound.mp3";
      // link.click();
      this.wavesurfer.load(link.href);
    },
     

    toggleAudio() {
      this.wavesurfer.playPause();
    },

    downloadFile() {
      // const blob = new Blob([this.downloadLink.data], { type: "audio/wave" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(this.downloadLink);
      console.log("link href = ", link.href)
      link.download = "sound.mp3";
      link.click()
    },


  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
};
</script>

<style scoped>
.audioplayer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.table {
  color: hsl(244, 67%, 45%);
  padding: 10px 20px;
  font: inherit;
  cursor: pointer;
  border-radius: 18px;
}

.button {
  border: 1px solid hsl(244, 56%, 21%);
  color: hsl(244, 67%, 45%);
  padding: 10px 20px;
  font: inherit;
  cursor: pointer;
  border-radius: 18px;
}

.flex {
  display: inline-flex;
}
</style>
