<template>
  <div class="container">
    <div class="col-lg-3 centered">
      <!-- <img src="../assets/logo.png" /> -->
    </div>
    <div class="col-lg-9 jumbotron">
      <h2>PDCST.ai</h2>
      <h3>Wat doet deze app?</h3>
      <p>U upload uw text en wij sturen u een mp3 met de gesproken text.</p>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";

export default {};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>